<template>
  <div
    class="flex border p-4 my-3 bg-white rounded-lg shadow-lg dark:bg-gray-800"
  >
    <div
      class="px-3 mr-4 rounded-full dark:text-orange-100 dark:bg-orange-500"
    >
      <i :style="`color: ${color}`" :class="`gg-dollar`"></i>
    </div>
    <div>
      <p class="text-sm font-medium text-gray-600 dark:text-gray-400 capitalize">
        {{label}} 
      </p>
      <div class="text-xs text-gray-500 dark:text-gray-400 mb-3 normal-case">({{ details }})</div>
      <p class="text-xl font-semibold text-gray-700 dark:text-gray-200">{{number ? toMoney(number) : toMoney(0)}}</p>
    </div>
  </div>
</template>

<script>
import { toMoney } from "../../services/formater";

export default {
  name: "CardFinanceiro",
  props: {
    label: String,
    details: String,
    color: String,
    number: Number, 
  },
  setup() {
    return { toMoney }
  }
};
</script>
